<template>
  <b-table-simple
    v-if="template"
    small
    striped
    responsive
    class="table-template-info"
  >
    <b-tbody>
      <b-tr>
        <b-td class="cell-info-title">id</b-td>
        <b-td>{{ template.id }}</b-td>
      </b-tr>
      <b-tr>
        <b-td class="cell-info-title">{{ $t("table.name") }}</b-td>
        <b-td
          ><b>{{ template.name }}</b></b-td
        >
      </b-tr>

      <!-- <b-tr>
            <b-td class="cell-info-title">Категория</b-td>
            <b-td></b-td>
          </b-tr> -->

      <template v-for="(metric, i) of template.metrics">
        <b-tr :key="`title-${i}`">
          <b-td class="cell-info-title"
            >{{ $t("table.metric") }} {{ ++i }}</b-td
          >
          <b-td
            ><b>{{ metric.name }}</b></b-td
          >
        </b-tr>
        <b-tr :key="`described-${i}`">
          <b-td class="cell-info-title">{{ $t("table.description") }}</b-td>
          <b-td>{{ metric.described }}</b-td>
        </b-tr>
        <b-tr :key="`type-${i}`">
          <b-td class="cell-info-title">{{ $t("table.type") }}</b-td>
          <b-td> {{ typeToStr(metric.type) }}</b-td>
        </b-tr>
        <b-tr :key="`status-${i}`">
          <b-td class="cell-info-title">{{ $t("table.status") }}</b-td>
          <b-td>
            <i v-if="metric.status" class="fe-eye" title="ON"></i>
            <i v-else class="fe-eye-off" title="OFF"></i>
          </b-td>
        </b-tr>
        <b-tr :key="`url-${i}`">
          <b-td class="cell-info-title">{{ $t("table.source") }}</b-td>
          <b-td
            ><div
              class="text-collapse"
              v-b-tooltip="{
                trigger: 'hover',
                placement: 'top',
                boundary: 'document',
                variant: 'primary',
                title: metric.url,
              }"
            >
              {{ metric.url }}
            </div></b-td
          >
        </b-tr>
        <b-tr :key="`field-${i}`">
          <b-td class="cell-info-title">{{ $t("table.field") }}</b-td>
          <b-td>
            {{ normilizeMetricField(metric) }}
          </b-td>
        </b-tr>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: {
    template: {
      type: Object,
      default: () => {},
    },
  },
  created() {},
  computed: {
    ...mapGetters(["sourceTypes"]),
  },
  methods: {
    typeToStr(id = null) {
      return this.sourceTypes.filter((t) => t.id === id)[0]?.title || " - ";
    },
    normilizeMetricField(metric) {
      if (metric && metric.field) {
        // if g table - normilize + 1
        if (metric.type === 1) {
          const cell = metric.field.split(":");
          return `${Number(cell[0]) + 1}:${Number(cell[1]) + 1}`;
        }

        return metric.field;
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.cell-info-title {
  width: 320px;
  text-align: right;
  font-weight: bold;
}

.text-collapse {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 400px;
}
</style>
