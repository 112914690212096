var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.template)?_c('b-table-simple',{staticClass:"table-template-info",attrs:{"small":"","striped":"","responsive":""}},[_c('b-tbody',[_c('b-tr',[_c('b-td',{staticClass:"cell-info-title"},[_vm._v("id")]),_c('b-td',[_vm._v(_vm._s(_vm.template.id))])],1),_c('b-tr',[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.name")))]),_c('b-td',[_c('b',[_vm._v(_vm._s(_vm.template.name))])])],1),_vm._l((_vm.template.metrics),function(metric,i){return [_c('b-tr',{key:`title-${i}`},[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.metric"))+" "+_vm._s(++i))]),_c('b-td',[_c('b',[_vm._v(_vm._s(metric.name))])])],1),_c('b-tr',{key:`described-${i}`},[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.description")))]),_c('b-td',[_vm._v(_vm._s(metric.described))])],1),_c('b-tr',{key:`type-${i}`},[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.type")))]),_c('b-td',[_vm._v(" "+_vm._s(_vm.typeToStr(metric.type)))])],1),_c('b-tr',{key:`status-${i}`},[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.status")))]),_c('b-td',[(metric.status)?_c('i',{staticClass:"fe-eye",attrs:{"title":"ON"}}):_c('i',{staticClass:"fe-eye-off",attrs:{"title":"OFF"}})])],1),_c('b-tr',{key:`url-${i}`},[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.source")))]),_c('b-td',[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:({
              trigger: 'hover',
              placement: 'top',
              boundary: 'document',
              variant: 'primary',
              title: metric.url,
            }),expression:"{\n              trigger: 'hover',\n              placement: 'top',\n              boundary: 'document',\n              variant: 'primary',\n              title: metric.url,\n            }"}],staticClass:"text-collapse"},[_vm._v(" "+_vm._s(metric.url)+" ")])])],1),_c('b-tr',{key:`field-${i}`},[_c('b-td',{staticClass:"cell-info-title"},[_vm._v(_vm._s(_vm.$t("table.field")))]),_c('b-td',[_vm._v(" "+_vm._s(_vm.normilizeMetricField(metric))+" ")])],1)]})],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }