<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <h2 class="text-center mb-4">{{ this.$t("my-template.info") }}</h2>
          </div>
        </div>

        <div v-if="loading" class="text-center">
          <b-spinner variant="primary"></b-spinner>
        </div>
        <div v-else>
          <!-- <pre>template: {{ template }}</pre> -->
          <TemplateInfo :template="template" />

          <!-- Get template metric -->
          <div class="metric-info col-lg-8 mx-auto mb-3">
            <div class="card">
              <div class="card-body">
                <b-alert
                  :variant="msg.type"
                  dismissible
                  class="mt-3"
                  v-model="msg.has"
                  :show="msg.text"
                  >{{ msg.text }}</b-alert
                >

                <form @submit.prevent novalidate class="form-horizontal">
                  <div class="form-group row">
                    <label
                      for="form-url"
                      class="col-sm-3 col-form-label required"
                      >{{ $t("table.range") }}</label
                    >
                    <div class="col-sm-9">
                      <div class="input-group">
                        <date-picker
                          v-model="daterange"
                          range
                          append-to-body
                          lang="en"
                          valueType="YYYY-MM-DD"
                        />
                        <div class="input-group-append">
                          <button
                            :disabled="inprogress"
                            class="btn btn-primary"
                            @click.prevent="getMetric"
                          >
                            {{ $t("btn.get-metric-data") }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <!-- <pre>daterange: {{ daterange }}</pre> -->
                <!-- <pre>metrics: {{ metrics }}</pre> -->
                <div
                  v-for="metric in template.metrics"
                  :key="metric.id"
                  class="col-12 mx-auto mb-4"
                >
                  <template v-if="currentMetricData(metrics, metric.name)">
                    <div class="text-center">
                      <h4 class="mb-1">{{ metric.name }}</h4>
                      <p v-text="metric.described"></p>
                    </div>

                    <MetricDiagram
                      :metric="currentMetricData(metrics, metric.name)"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-auto mx-auto text-center">
              <router-link
                to="/my-templates/"
                class="btn btn-warning mx-2 mb-2"
              >
                {{ $t("btn.back") }}
              </router-link>
              <router-link
                :to="`/my-templates/edit/${$route.params.id}`"
                class="btn btn-primary mx-2 mb-2 mr-lg-0"
                >{{ $t("btn.edit") }}</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import axios from "axios";
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/Page-header";
import TemplateInfo from "@/components/Template-info";
import DatePicker from "vue2-datepicker";
import { required } from "vuelidate/lib/validators";
import MetricDiagram from "@/components/Metric-diagram";

export default {
  metaInfo() {
    return {
      title: this.$appConfig.title + " | " + this.$t("my-template.info"),
    };
  },
  components: {
    Layout,
    PageHeader,
    TemplateInfo,
    DatePicker,
    MetricDiagram,
  },
  data() {
    return {
      title: "my-template.info",
      items: [
        {
          text: this.$appConfig.title,
          to: "/",
        },
        {
          tran: "sidebar.my-templates",
          to: "/my-templates",
        },
        {
          tran: "my-template.info",
          active: true,
        },
      ],
      loading: true,
      template: "",

      metrics: "",

      submitted: false,
      inprogress: false,
      msg: {
        has: false,
        type: "",
        text: "",
      },
      daterange: "",
    };
  },
  async created() {
    await this.loadData();
    await this.getMetric();
  },
  computed: {},
  methods: {
    async loadData() {
      this.loading = true;

      try {
        await Promise.all([
          this.$store.dispatch("fetchSourceTypes"),
          this.loadTemplate(),
        ]);
      } catch (error) {
        console.log("loadData Error: ", error);
      } finally {
        this.loading = false;
      }
    },
    async loadTemplate() {
      try {
        const response = await axios.get(
          this.$urls.URL_USER_TEMPLATES + `/show/${this.$route.params.id}`
        );
        this.template = response.data;
      } catch (error) {
        console.log("loadTemplate, error: ", error);
      }
    },
    async getMetric() {
      this.submitted = true;

      this.msg = {
        has: false,
        type: "",
        text: "",
      };

      // this.$v.$touch();

      // if (!this.$v.$invalid) {
      this.inprogress = true;
      this.metrics = "";

      const formData = new FormData();

      if (this.daterange[0]) {
        formData.append("start_date", this.daterange[0]);
      }
      if (this.daterange[1]) {
        formData.append("end_date", this.daterange[1]);
      }

      // Display the key/value pairs ###debug
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + " :", pair[1]);
      // }

      try {
        const response = await axios.post(
          this.$urls.URL_GET_METRIC + `/${this.$route.params.id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
            },
          }
        );

        // console.log("getMetric, response: ", response);
        this.metrics = response.data;

        this.inprogress = false;
      } catch (error) {
        console.log("Error: ", error);
        this.msg.has = true;
        this.msg.type = "danger";
        // this.msg.text =
        //   error.response?.data?.message || this.$t("city.msg-edit-error");
        this.msg.text =
          error.response?.data?.message || "Error on try get metric data";

        this.inprogress = false;

        if (error.response.data.errors) {
          for (const [key, value] of Object.entries(
            error.response.data.errors
          )) {
            this.msg.text += `[${key}: ${value}] `;
          }
        }
      }
      // }
    },
    currentMetricData(data, name) {
      // filter only current metric data = name
      // return data;

      if (!data) {
        return false;
      }

      return data.map((d) => {
        const metrics = d.metrics.filter((m) => m[0].name === name);

        return {
          ...d,
          metrics,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 180px;
}

.metric-info {
  ::v-deep .input-group {
    flex-wrap: nowrap;
  }
  ::v-deep .input-group input.mx-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .is-invalid ::v-deep input.mx-input {
    border-color: #f1556c !important;
  }

  .btn {
    min-width: 0;
    white-space: nowrap;
  }
}
</style>
